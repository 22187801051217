import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import { RpcError } from '@dealroadshow/json-rpc-dispatcher';
import createAction, { IAction } from './createAction';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';

type AllOrNone<T> = T | { [K in keyof T]?: never };
export type TDefaultMethod = (...args: any) => any;

type TMethodPayload<TMethod extends TDefaultMethod> = Parameters<TMethod>[0];
type TMethodResponse<TMethod extends TDefaultMethod> = Awaited<ReturnType<TMethod>>;

// Action Types

export interface IReactReducerDispatch {
  (action: IAction): void,
}
export type TActionTypes = Array<string | null>;

type DispatchAndTypes = AllOrNone<{
  dispatch: IReactReducerDispatch,
  types: Array<string | null>, // 0 - Request, 1 - Success, 2 - Error
}>;

// Callbacks

export interface IOnSuccessCallbackPayload<TMethod extends TDefaultMethod> {
  response: TMethodResponse<TMethod>,
  payload: TMethodPayload<TMethod>,
  dispatch: IReactReducerDispatch,
}

export interface IOnErrorCallbackPayload<TMethod extends TDefaultMethod> {
  error: RpcError,
  payload: TMethodPayload<TMethod>,
  dispatch: IReactReducerDispatch,
}

export type TCallbacks<TMethod extends TDefaultMethod> = {
  onSuccess?: (payload: IOnSuccessCallbackPayload<TMethod>) => void,
  onError?: (payload: IOnErrorCallbackPayload<TMethod>) => void,
};

// Request itself

export type TRequestConfig<TMethod extends TDefaultMethod> = {
  method: (payload?: TMethodPayload<TMethod>) => Promise<TMethodResponse<TMethod>>,
  callbacks?: TCallbacks<TMethod>,
} & DispatchAndTypes;

export interface IAsyncRequest<TMethod extends TDefaultMethod> {
  (payload?: TMethodPayload<TMethod>): Promise<void>,
}

/**
 * Note on types: the types for method's payload and response should be described in the method's repository.
 * Then method's type should be passed to createAsyncRequest with generics, e.g:
 *
 * const request = createAsyncRequest<typeof someRepository.doSomething>(...);
 */
export const createAsyncRequest = <TMethod extends TDefaultMethod>(
  {
    method,
    dispatch,
    types: [
      REQUEST_TYPE,
      SUCCESS_TYPE,
      ERROR_TYPE,
    ] = [],
    callbacks: {
      onSuccess = noop,
      onError = noop,
    } = {},
  }: TRequestConfig<TMethod>,
): IAsyncRequest<TMethod> => async (payload?: TMethodPayload<TMethod>): Promise<void> => {
  if (dispatch && REQUEST_TYPE) {
    dispatch(createAction(REQUEST_TYPE));
  }

  try {
    const response = await method(payload);

    if (!isEqual(onSuccess, noop)) {
      onSuccess({ response, payload, dispatch });
    }

    if (dispatch && SUCCESS_TYPE) {
      dispatch(createAction(SUCCESS_TYPE, response));
    }
  } catch (error) {
    if (!isEqual(onError, noop)) {
      onError({ error, payload, dispatch });
    } else {
      AlertManager.error(getErrorMessage(error));
    }
    if (dispatch && ERROR_TYPE) {
      dispatch(createAction(ERROR_TYPE, error));
    }
  }
};

export default createAsyncRequest;
