import cn from 'classnames';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import formatExtensionsByMimeType from '@/Framework/dataHelpers/formatters/formatExtensionsByMimeType';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import Tooltip from '@dealroadshow/uikit/core/components/Tooltip';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { getMaxSizeValue } from '../helpers/getMaxSizeValue';
import DropzoneInternalField, { IDropzoneInternalFieldDefaultProps } from './DropzoneInternalField';

import styles from '../upload.scss';

export interface IUploadDropzoneOneFileProps {
  dropzoneHelperClassName?: string,
  onUpload: (files: Array<Record<string, any>>) => void,
  allowedFileTypes: string,
  allowedFileText: string,
  formFieldName: string,
  uploadName: string,
  fileName?: string,
  maxSize?: number,
  maxSizeUnits?: string,
  maxSizeErrorMessage?: string,
  dropzoneInternalFieldComponent?: React.ComponentType<IDropzoneInternalFieldDefaultProps>,
  disabled?: boolean,
  disabledTooltip?: string,
}

const ZERO_SIZE = 0;

const UploadDropzoneOneFile = ({
  dropzoneHelperClassName = '',
  onUpload,
  allowedFileTypes,
  allowedFileText,
  formFieldName,
  uploadName,
  maxSize = Infinity,
  maxSizeUnits,
  maxSizeErrorMessage,
  fileName,
  dropzoneInternalFieldComponent: DropzoneInternalFieldComponent = DropzoneInternalField,
  disabled,
  disabledTooltip,
}: IUploadDropzoneOneFileProps) => {
  const [showMaxSizeError, setShowMaxSizeError] = useState(false);

  const onDropCallback = (acceptedDropFiles, rejectedDropFiles) => {
    if ((acceptedDropFiles.length + rejectedDropFiles.length) === 1) {
      let acceptedFiles = [];
      let rejectedFiles = [];

      if (acceptedDropFiles.length) {
        const isZeroBytesFile = acceptedDropFiles.every((file) => file.size === ZERO_SIZE);

        acceptedFiles = acceptedDropFiles.filter((file) => file.size <= maxSize && !isZeroBytesFile);
        rejectedFiles = acceptedDropFiles.filter((file) => file.size > maxSize || isZeroBytesFile);

        if (acceptedFiles.length) {
          onUpload(acceptedFiles);
          setShowMaxSizeError(false);
        }
        if (rejectedFiles.length) {
          if (maxSizeErrorMessage) {
            setShowMaxSizeError(true);
          } else if (isZeroBytesFile) {
            const message = getMessage(
              messageCodes.UPLOAD_ZERO_SIZE,
              { fileName: rejectedFiles[0].name },
            );
            AlertManager.error(message);
          } else {
            const message = getMessage(
              messageCodes.UPLOAD_MAX_SIZE,
              {
                size: getMaxSizeValue(maxSize, maxSizeUnits),
                units: maxSizeUnits,
              },
            );
            AlertManager.warning(message);
          }
        }
      }

      if (rejectedDropFiles.length) {
        let rejectedExtensionFiles = rejectedDropFiles
          .filter(({ file }) => (file instanceof File))
          .map(({ file }) => file);

        if (rejectedExtensionFiles.length) {
          let acceptedExt = allowedFileTypes.split(',')
            .map((accept) => accept.trim());
          rejectedExtensionFiles.forEach((file) => {
            let name = file.name.split('.');
            if (name.length > 1 && !acceptedExt.includes(name[name.length - 1])) {
              AlertManager.error(getMessage(
                messageCodes.UPLOAD_INVALID_EXTENSION,
                {
                  fileName: file.name,
                  validExtensions: allowedFileText,
                },
              ));
            }
          });
        }
      }
    }

    if ((acceptedDropFiles.length + rejectedDropFiles.length) > 1) {
      let suffixText = formFieldName !== 'logo' ? ' file' : '';
      AlertManager.error(getMessage(
        messageCodes.UPLOAD_ONLY_ONE_FILE_ALLOWED,
        { uploadName: `${ uploadName.toLocaleLowerCase() }${ suffixText }` },
      ));
    }
  };

  const dropzoneClassName = cn(
    { [styles.isDropZoneMaxSizeError]: showMaxSizeError },
    styles.dropzone,
    dropzoneHelperClassName,
  );

  const isTooltipHidden = !disabled;

  return (
    <>
      <Tooltip
        disabled={ isTooltipHidden }
        content={ disabledTooltip }
        wrpClassName={ styles.dropzoneTooltip }
        containerClassName={ styles.dropzoneTooltip }
      >
        <Dropzone
          key="dropzone"
          multiple={ false }
          accept={ formatExtensionsByMimeType(allowedFileTypes) }
          onDrop={ onDropCallback }
          disabled={ disabled }
          useFsAccessApi
        >
          { ({
            getRootProps,
            getInputProps,
          }) => (
            <div className={ dropzoneClassName } { ...getRootProps() }>
              <DropzoneInternalFieldComponent
                fileName={ fileName }
                uploadName={ uploadName }
                allowedFileText={ allowedFileText }
                disabled={ disabled }
              >
                <input { ...getInputProps() } />
              </DropzoneInternalFieldComponent>
            </div>
          ) }
        </Dropzone>
      </Tooltip>
      { showMaxSizeError && <div className={ styles.error }>{ maxSizeErrorMessage }</div> }
    </>
  );
};

export default UploadDropzoneOneFile;
