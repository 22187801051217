import { combineReducers } from 'redux';
import data from './data/reducer';
import title from './title/reducer';
import permissions from './permissions/reducer';

export default combineReducers({
  data,
  title,
  permissions,
});
