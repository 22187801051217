import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import DatabasesRepository from '@/condor/infrastructure/repository/admin/DatabasesRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import * as actionTypes from './actionTypes';

/**
 * @param {Array} payload
 * @return {Object}
 */
function requestEventLogOffsetSuccess(payload) {
  return {
    type: actionTypes.REQUEST_EVENT_LOG_OFFSET_SUCCESS,
    payload,
  };
}

/**
 * @param {Number} payload
 * @return {Object}
 */
function requestEventLogMaxIdSuccess(payload) {
  return {
    type: actionTypes.REQUEST_EVENT_LOG_MAX_ID_SUCCESS,
    payload,
  };
}

/**
 * @param {moment} payload
 * @return {Object}
 */
function eventLogOffsetSetLastUpdateTime(payload) {
  return {
    type: actionTypes.EVENT_LOG_OFFSET_LAST_UPDATE_TIME,
    payload,
  };
}

/**
 * @param {String} payload
 * @return {Object}
 */
function requestEventLogOffsetError(payload) {
  return {
    type: actionTypes.REQUEST_EVENT_LOG_OFFSET_ERROR,
    payload,
  };
}

export function getEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function getFinsightEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getFinsightEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function getFinsightStratsEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getFinsightStratsEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function getFinsightAnalyticsEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getFinsightAnalyticsEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function getDmPortalEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getDmPortalEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function getCondorEventLogOffset() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_EVENT_LOG_OFFSET });
    try {
      const databasesRepository = getState().container.get(DatabasesRepository);
      const eventLog = await databasesRepository.getCondorEventLogOffset();
      dispatch(eventLogOffsetSetLastUpdateTime(new Date()));
      dispatch(requestEventLogOffsetSuccess(eventLog.eventLogOffsets));
      dispatch(requestEventLogMaxIdSuccess(eventLog.maxId));
    } catch (errorResponse) {
      dispatch(requestEventLogOffsetError(errorResponse));
      AlertManager.error(getErrorMessage(errorResponse));
    }
  };
}

export function resetEventLogOffset() {
  return async (dispatch) => {
    dispatch({ type: actionTypes.EVENT_LOG_OFFSET_RESET });
  };
}
